.balls-wrapper {
    display: grid;
    overflow: hidden;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
}
.balls {
    display: grid;
    grid-template-columns: repeat(8, min-content);
    justify-content: space-between;
    grid-gap: 7px;
    overflow: hidden;
    margin: auto;
    width: 100%;
}