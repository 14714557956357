.left {
	overflow: hidden;
}
.airbnb-sidebar-items {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	grid-gap: 5px;
	overflow: hidden;
	color: rgba(255, 255, 255, 1);
}
.sidebar-item-wrapper {
	display: grid;
	background-color: rgba(33, 33, 43, 1);
	border-radius: 20px;
	overflow: hidden;
}
.left-item-wrapper {
	display: grid;
	grid-template-rows: 30px auto;
	background-color: rgba(33, 33, 43, 1);
	border-radius: 20px;
	overflow: hidden;
}
.sidebar-sub-title {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1.2em;
	font-weight: 600;
	padding-left: 20px;	
	padding-top: 5px;
	padding-bottom: 10px;
	letter-spacing: 1.2px;
}
.airbnb-message-wrapper {
	display: grid; 
	height: 100%;
	grid-gap: 10px;
}