.airbnb-location-wrapper {
	display: flex; 
	gap: 5px; 
	padding-left: 20px; 
	padding-right: 20px; 
	align-items: center;
	font-size: 0.7em;
	white-space: nowrap;
	overflow: hidden;
}
.location-pin {
	width: 10px; 
	height: 14px;
	transform: translateY(-2px);
}