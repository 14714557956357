.fixTableHead { 
  overflow-y: scroll; 
  text-align: center;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead{ 
    position: sticky; 
    top: 0; 
    background-color: rgba(33, 33, 43, 1);
  } 
  td {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  tr:hover {
    background: rgba(70, 70, 70, 1);
  }
  th { 
    border-top: none;
    border-bottom: none;
    padding: 8px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  } 
  td {
      padding: 8px;
      border-top: none;
  }
} 