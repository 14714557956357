.evaluation-wrapper {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
	align-items: center;
}
.evaluation-value {
	font-weight: 700;
	font-size: 1.2em;
	letter-spacing: 2px;
}