.map-search-wrapper {
  display: grid;
  grid-template-columns: min-content auto min-content;
  justify-self: end;
  padding: 2px;
  padding-left: 10px;
  position: relative;
}
.maps-input {
  overflow-x: hidden;
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
  border: none;
  height: 30px;
  font-size: 1em;
  text-indent: 10px;
}
.maps-input:focus {
  outline: none;
}
.cancel-cross-wrapper {
  width: 30px;
  display: grid;
}
.cancel-cross {
  width: 15px;
  cursor: pointer;
  align-self: center;
  justify-self: center;
}
.airbnb-search-icon {
  cursor: pointer;
  height: 20px;
  align-self: center;
}