.right-wrapper {
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	overflow: hidden;
	grid-gap: 5px;
}
.right-item-wrapper {
	display: grid;
	background-color: rgba(33, 33, 43, 1);
	border-radius: 20px;
	overflow: hidden;
	grid-template-rows: min-content auto;
}
.right-item-title {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1.2em;
	font-weight: 600;
	padding-left: 20px;	
	padding-top: 5px;
	padding-bottom: 10px;
	letter-spacing: 1.2px;
}
.gauge-graphics-wrapper {
	display: grid; 
	grid-template-columns: 2fr 3fr; 
	padding-left: 20px;
}