.listing-wrapper {
  position: absolute;
  width: 100%;
  top: 60px;
  height: calc(calc(var(--vh, 1vh) * 100) - 60px);
  max-height: calc(calc(var(--vh, 1vh) * 100) - 60px);
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 4;
  display: flex;
  justify-content: space-around;
  color: rgba(255, 255, 255, 1);
}
.listing {
  background-color: rgba(32, 32, 43, 1);
  width: 35%;
  max-width: 768px;
  display: grid;
}
.listing-body {
  display: grid;
  overflow: hidden;
  grid-template-rows: auto min-content;
}