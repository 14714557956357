.main-wrapper {
  background: rgba(23, 23, 32, 1);
  overflow: hidden;
  display: grid;
  grid-template-columns: 5fr 14fr 5fr;
  padding: 5px;
  grid-gap: 5px;
}
.bottom {
  overflow: hidden;
  display: none;
}
.main-bottom {
  display: none;
}
@media screen and (max-width: 768px) {
  .main-wrapper {
    grid-template-columns: auto;
    grid-template-rows: 3fr 2fr;
  }
  .right-wrapper {
    display: none;
  }
   .main {
    grid-template-columns: auto;
    grid-template-rows: 3fr 2fr;
  }
  .main-bottom {
    display: flex;
    background-color: rgba(255, 255, 255, 1);
    align-items: center;
    justify-content: space-evenly;
  }
  .prices {
    display: none;
  }
  .left {
    display: none;
  }
  .right {
    display: none;
  }
  .bottom {
    display: block;
  }
}