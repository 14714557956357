.search-suggestions {
  position: absolute;
  top: 35px;
  left: 0px;
  list-style-type: none;
  padding: 10px;
  padding-right: 20px;
  margin: 0;
  width: 100%;
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(23, 23, 32, 1);
  z-index: 7;
  border-radius: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .12); 
  cursor: pointer;
}
.search-suggestions {
  li:hover{
    background-color: rgba(32, 32, 43, 1);
    border-radius: 10px;
  }
}
.current-suggestion {
  width: 100%;
  display: flex;
  gap: 10px;
  overflow: hidden;
  padding: 5px;
  padding-left: 10px;
}
                  
