.map-header-wrapper {
  width: 100%;
  position: absolute;
  display: grid;
  grid-template-columns: 80px auto 80px;
}
.map-header {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 5px;
  background-color: rgba(23, 23, 32, 1);
  height: 30px;
  z-index: 4;
}
.map-header:before {
  content: '';
  border-top: 30px solid rgba(23, 23, 32, 1);
  border-left: 50px solid transparent;
  position: absolute;
  left: 30px;
}
.map-header:after {
  content: '';
  border-top: 30px solid rgba(23, 23, 32, 1);
  border-right: 50px solid transparent;
  position: absolute;
  left: calc(100% - 80px);
}

.dropdown-wrapper {
  white-space: nowrap;
  position: relative;
  width: 80px;
  height: 20px;
  cursor: pointer;
  background-color: rgba(23, 23, 34, 1);
  border-radius: 5px;
  border: 2px solid rgba(70, 70, 80, 0.5);
  color: rgba(255, 255, 255, 0.6);
  z-index: 6;
  font-size: 0.8em;
}
.dropdown-header {
  height: 20px;
  display: grid;
  grid-template-columns: auto 15px;
  align-items: center;
  text-align: center;
  img {
    margin: auto;
  }
}
.suggestions {
  top: 22px;
  text-align: center;
  position: absolute;
  list-style-type: none;
  padding: 0;
  left: 0;
  margin: 0;
  width: 100%;
  background-color: rgba(23, 23, 34, 1);
  color: rgba(126, 126, 132, 1);
  border-radius: 0px 0px 5px 5px;
  li {
    padding: 5px;
  }
  li:hover {
    border-radius: 0px 0px 5px 5px;
    background-color: rgba(43, 20, 120, 1);
    color: rgba(255, 255, 255, 1);
  }
}
// mobile-style
@media screen and (max-width: 768px) {
 .map-header-wrapper {
   grid-template-columns: auto;
 } 
 .map-header {
    grid-gap: 3px;
    background-color: transparent;
  }
  .map-header:before {
    display: none;
  }
  .map-header:after {
    display: none;
  }
}