.main-header{
 background: linear-gradient(
    to bottom,
    rgba(50, 50, 50, 1),
    rgba(30, 30, 30, 1)
  );
  display: grid;
  grid-template-columns: min-content auto auto min-content;
  grid-gap: 8px;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
}